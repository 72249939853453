@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Raleway&display=swap');

@layer base {
  body {
    @apply font-[sans-serif];
    /* @apply font-[sans-serif] */
  }

  li {
    @apply px-4;
    @apply cursor-pointer;
  }
}